import { Box, BlockStack, Spinner } from '@shopify/polaris';

const SuspenseFallbackSpinner = () => {
  return (
    <Box width='100%' padding='1000'>
      <BlockStack align='center' inlineAlign='center'>
        <Spinner />
      </BlockStack>
    </Box>
  );
};

export default SuspenseFallbackSpinner;
