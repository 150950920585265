import React from 'react';
import Head from 'next/head';

const PageTitle = ({ title }: { title: string }) => {
  const pageTitle = `${title} | PushOwl Dashboard`;

  return (
    <Head>
      <title>{pageTitle}</title>
    </Head>
  );
};

export default PageTitle;
